<template>
	<div class="quieromifactura-estatus">
		<div
			@click="showWindow(status.link, status.code)"
			:class="[
				'chips', 
				status.code == 200 ? 'chips-200' : (status.code == 201 ? 'chips-201' : (status.code == -1 ? 'chips-1' : 'chips-500'))
			]"
		>
			{{ status.text }}
			<div v-if="status.code == 201" style="position: relative; left: 10px;">
				<img :src="externalLink" width="14px" class="external">
			</div>
			<div v-else-if="status.code == 200" style="position: relative; left: 10px;">
				<img :src="satLogo" width="14px" class="external">
			</div>
			<div v-else-if="status.code == -1" style="position: relative; left: 10px;">
				<img :src="exclamation" width="14px" class="external">
			</div>
			<div v-else style="position: relative; left: 10px;">
				<img :src="exclamation" width="14px" class="external">
			</div>
		</div>
	</div>
</template>

<script>
import externalLink from '@/modules/quieromifactura/img/external-link.svg';
import satLogo from '@/modules/quieromifactura/img/sat.svg';
import exclamation from '@/modules/quieromifactura/img/exclamation.svg';

export default {
	name: 'quieromifactura-estatus',
	mixins: [],

	props: {
		status: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {
			externalLink,
			satLogo,
			exclamation
		};
	},

	watch: {
		status: {
			immediate: true,
			handler() {},
		},
	},

	methods: {
		showWindow(link, code) {
			if (code != 500 && code != -1) {
				window.open(
					link,
					'_blank',
					'Location=0, Menubar=0, Toolbar=0, Directories=0, Resizable=0, Scrollbars=0, Fullscreen=0, Titlebar=0, Status=0, height=800, width=1200, top=100, left=300'
				);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.chips {
	text-decoration: none;
	font-weight: normal;	
	display: inline-flex;
	padding: 3px 18px;
	border-radius: 16px;
	align-items: center;
	min-height: 32px;
	height: 1px;
	&.chips-200 {
		color: #1e90ff;
		background: white;
		border: 2px solid #1e90ff;
		cursor: pointer;
	}
	&.chips-201 {
		color: white;
		background: #ec004a;
		border: 2px solid red;
		cursor: pointer;
	}
	&.chips-500 {
		color: #ec004a;
		background: white;
		border: 2px solid #ec004a;
	}
	&.chips-1 {
		color: #ec004a;
		background: white;
	}
}
</style>