<template>
	<div class="signio-target">
		<person-list-item
            :person="innerData.target"
            class="--nowrap"
            formal
			v-bind="$attrs"
        ></person-list-item>
	</div>
</template>

<script>
import useI18n from '@/modules/i18n/mixins/useI18n.js';
import useApi from '@/modules/api/mixins/useApi';
import PersonListItem from '@/modules/core/components/Person/ListItem.vue';

export default {
	name: 'signio-target',
	mixins: [useI18n, useApi],
	components: { PersonListItem },
	props: {
		data: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {
            innerData: this.data,
		};
	},
};
</script>

<style lang="scss"></style>
