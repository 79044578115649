<template>
	<div class="dian-sequence">
		<span
			:class="[
				dian.clientResponse == 1
					? 'accepted'
					: dian.clientResponse == 0
					? 'rejected'
					: null,
				,
				dian.canceled ? 'canceled' : null,
			]"
		>
			<span :title="dian.canceled ? 'Documento anulada' : (dian.status == 500 ? 'Documento rechazado' : 'Documento vigente')" style="display: inline-flex; padding-right: 3px;"> 
				{{ dian.sequence }}
				<div v-if="dian.canceled" style="position: relative; left: 7px; top: 0px;">
					<img :src="exclamation" width="14" class="external">
				</div>
				<div v-if="dian.status == 500">
					<span class="messageError">rechazado</span>
				</div>
			</span>
		</span>
		<a :href="dian.link" target="_blank" v-if="!dian.canceled && dian.status != 500">
			<img
				:src="dianSuccess"
				:title="[`Revisar documento ${this.dian.sequence} en la DIAN`]"
				width="12"
			/>
		</a>
	</div>
</template>

<script>
import useI18n from '@/modules/i18n/mixins/useI18n.js';
import useApi from '@/modules/api/mixins/useApi';

import dianSuccess from '@/modules/dian/img/dian-success.png';
import exclamation from '@/modules/dian/img/exclamation.svg';

export default {
	name: 'dian-sequence',
	mixins: [useI18n, useApi],
	props: {
		dian: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			dianSuccess,
			exclamation
		};
	},
	mounted() {},
};
</script>

<style lang="scss" scoped>
.dian-sequence {
	position: relative;
	height: 40px;
	margin-top: 13px;
}
.link {
	text-decoration: none;
	color: black;
	font-weight: normal;
}
.accepted {
	color: green;
	font-weight: bold;
	/*background:#f5fff5;*/
}
.rejected {
	color: red;
	font-weight: bold;
	/*background:#f9e9e9;*/
}
.canceledDocument {
	position: absolute;
	color: grey;
	transform: rotate(-25deg);
	margin-left: -90px;
}
.canceled {
	text-decoration: line-through;
	color: red;
	border-radius: 18px;
	border: 2px solid red;
	padding: 2px 10px 2px 10px;
	width: 200px;
}
.messageError {
	position: absolute;
	top: -11px;
	font-size: 12px;
	color: red;
	border: 1px solid red;
	border-radius: 25px;
	padding: 0px 5px;
	text-align: center;
}
</style>
