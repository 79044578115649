<template>
  <div
    class="ui-output"
    :class="`type-${type}`"
  >
    <span v-if="type == 'date'">{{ $ts(value, $attrs.format || 'date') }}</span>
    <span v-else-if="type == 'timestamp'">{{ $ts(value, $attrs.format || null) }}</span>
    <span v-else-if="type == 'day'">{{ $date(value, $attrs.format || 'day') }}</span>
    <span v-else-if="type == 'currency'">{{ $cr(value, $attrs.currency) }}</span>

    <person-list-item
      v-else-if="type == 'person'"
      :person="value"
      class="--nowrap"
      formal
      v-bind="$attrs"
    ></person-list-item>

    <ul
      v-else-if="type == 'list'"
      class="list-array"
    >
      <li
        v-for="(val,i) in listValues"
        :key="i"
        :class="`item-value-${val.value}`"
      >{{ val.text }}</li>
    </ul>

    <div
      v-else-if="type == 'file'"
      class="file-list"
    >
      <a
        v-for="(file,i) in value"
        :key="i"
        :href="file.url"
        target="_blank"
        @click.stop
      >
        <ui-item
          class="ui-clickable"
          :text="file.name"
          :secondary="file.size | bytes"
          :icon="'mime:'+file.mimetype"
        ></ui-item>
      </a>
    </div>

    <dian-sequence 
      v-else-if="type == 'diansequence'"
      :dian="value"
    >
    </dian-sequence>

    <dian-document-id
      v-else-if="type == 'diandocumentid'"
      :document="value"
    ></dian-document-id>

    <dian-validation
      v-else-if="type == 'dianvalidation'"
      :validation="value"
    ></dian-validation>

    <dian-links
      v-else-if="type == 'dianlinks'"
      :links="value"
    ></dian-links>

    <protec-data-file
      v-else-if="type == 'protecdatafile'"
      :link="value"
    ></protec-data-file>

    <protec-data-document
      v-else-if="type == 'protecdatadocument'"
      :data="value"
    ></protec-data-document>

    <quieromifactura-estatus
      v-else-if="type == 'quieromifacturaestatus'"
      :status="value"
    >
    </quieromifactura-estatus>

    <signio-link
      v-else-if="type == 'signiolink'"
      :link="value"
    ></signio-link>

    <signio-document
      v-else-if="type == 'signiodocument'"
      :data="value"
    ></signio-document>

    <signio-target
      v-else-if="type == 'signiotarget'"
      :data="value"
    >
    </signio-target>

    <div v-else>{{ value }}</div>
  </div>
</template>

<script>
import useI18n from '@/modules/i18n/mixins/useI18n.js';
import PersonListItem from '@/modules/core/components/Person/ListItem.vue';
import DianSequence from '@/modules/dian/components/Sequence/Sequence.vue';
import DianDocumentId from '@/modules/dian/components/Documentid/Documentid.vue';
import DianValidation from '@/modules/dian/components/Validation/Validation.vue';
import DianLinks from '@/modules/dian/components/Links/Links.vue';
import ProtecDataFile from '@/modules/protecdata/components/File/File.vue';
import ProtecDataDocument from '@/modules/protecdata/components/Document/Document.vue';
import QuieromifacturaEstatus from '@/modules/quieromifactura/components/Quieromifacturaestatus.vue';
import SignioLink from '@/modules/signio/components/File/File.vue';
import SignioDocument from '@/modules/signio/components/Document/Document.vue';
import SignioTarget from '@/modules/signio/components/Target/Target.vue';
import { UiItem } from '@/modules/ui/components';
import bytes from '@/filters/bytes.js';

export default {
  name: 'ui-output',

  mixins: [useI18n],
  components: { PersonListItem, UiItem, DianSequence, DianDocumentId, DianValidation, DianLinks, ProtecDataFile, ProtecDataDocument, QuieromifacturaEstatus, SignioLink, SignioDocument, SignioTarget },
  filters: { bytes },

  props: {
    value: {
      required: false,
      default: null,
    },

    type: {
      required: false,
      default: 'text',
    },

    options: {
      required: false,
      default: null,
    },
  },

  computed: {
    listValues() {
      if (this.type != 'list' || !this.value) {
        return [];
      }

      let arrValue = Array.isArray(this.value) ? this.value : [this.value];

      return arrValue.map((val) => {
        let matchingOption =
          this.options && this.options.values
            ? this.options.values.find((opt) => opt.value == val)
            : null;

        return {
          value: val,
          text: matchingOption ? matchingOption.text : val,
        };
      });
    },
  },
};
</script>

<style lang="scss">
.ui-output {
  &.type-date,
  &.type-day,
  &.type-timestamp {
    white-space: nowrap;
  }

  .list-array {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      padding: 2px 8px;
      border-radius: var(--ui-radius);
      margin-right: 3px;

      font-family: var(--ui-font-secondary);
      font-size: 11px;

      background-color: var(--ui-color-primary);
      color: #fff;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>