<template>
	<div class="dian-validation">
		<div>{{ message }}</div>
		<div v-if="notifications.length > 0">
			<div
				v-for="notification in notifications"
				:key="notification.id"
				class="notification"
			>
				<span class="notification-message">{{ notification.code }}</span
				>: {{ notification.message }}
			</div>
		</div>
	</div>
</template>

<script>
import useI18n from '@/modules/i18n/mixins/useI18n.js';
import useApi from '@/modules/api/mixins/useApi';

export default {
	name: 'dian-validation',
	mixins: [useI18n, useApi],
	props: {
		validation: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {
			message: '',
			notifications: [],
		};
	},

	watch: {
		validation: {
			immediate: true,
			handler: function(val) {
				this.parseValidation(val);
			},
		},
	},

	filters: {},

	methods: {
		parseValidation(data) {
			this.message = data.response;
			if (data.status === 100 || data.status === 500) {
				if (typeof this.message === 'string') {
					if (data.message !== undefined) {
						this.notifications.push({ message: data.message, code: data.code });
					}
				} else {
					this.message = data.response;
					let notificationsTemp = JSON.parse(data.errors);
					const regex = /^([A-Z].*[0-9])(\s.*)/gm;
					let m;
					notificationsTemp.forEach((notification) => {
						while ((m = regex.exec(notification)) !== null) {
							if (m.index === regex.lastIndex) {
								regex.lastIndex++;
							}
							//m.forEach((match, groupIndex) => {
							//	console.log(`Found match, group ${groupIndex}: ${match}`);
							//});
							this.notifications.push({ message: m[2], code: m[1] });
						}
					});
				}
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.notification-message {
	color: #045692;
	background: #deefff;
}
.notification {
	padding-top: 5px;
}
</style>
